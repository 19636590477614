<template>
	<section class="content-main">
		<!--基本信息-->
		<el-row>
			<el-col :span="12">
				<el-form :model="form" label-width="140px" :rules="formRules" ref="form">
					<el-form-item label="代销证">
						<el-upload list-type="picture-card" :limit='4' :action="uploadurl" :data="dataToken" :on-preview="handlePictureCardPreview"
						 :on-remove="handleRemove" :on-success="uploaderCertificateSuccess" :on-exceed="exceed" :file-list="form.agent_certificate_pic">
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="身份证">
						<el-upload class="avatar-uploader" :action="uploadurl" :data="dataToken" :show-file-list="false" :on-success="uploaderIdentityFrontSuccess">
							<img v-if="form.identity_pic.front" :src="form.identity_pic.front" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon">正面</i>
							<div v-if="form.identity_pic.front" @click.stop="showImg = form.identity_pic.front" class="seeBigImg">放大</div>
						</el-upload>
						<el-upload class="avatar-uploader" :action="uploadurl" :data="dataToken" :show-file-list="false" :on-success="uploaderIdentityContrarySuccess">
							<img v-if="form.identity_pic.contrary" :src="form.identity_pic.contrary" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon">反面</i>
							<div v-if="form.identity_pic.contrary" @click.stop="showImg = form.identity_pic.contrary" class="seeBigImg">放大</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="联系人" prop="contact">
						<el-input v-model.trim="form.contact" class="short-width"></el-input>
					</el-form-item>
					<el-form-item label="联系人身份证号码" prop="identity_num">
						<el-input v-model.trim="form.identity_num" class="short-width"></el-input>
					</el-form-item>
					<el-form-item label="体彩编号">
						<el-input v-model.trim="form.sport_lottery_seq" class="short-width"></el-input>
					</el-form-item>
					<el-form-item label="福彩编号">
						<el-input v-model.trim="form.welfare_lottery_seq" class="short-width"></el-input>
					</el-form-item>
					<el-form-item label="地区">
						<el-select v-model="form.province_id" placeholder="请选择省份">
							<el-option v-for="i in provinceList" :key="i.id" :label="i.name" :value="i.id">
							</el-option>
						</el-select>
						<el-select v-if="form.province_id" v-model="form.city_id" placeholder="请选择县市">
							<el-option v-for="i in cityList" :key="i.id" :label="i.name" :value="i.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label=" ">
						<el-button type="primary" @click.native="addSubmit" :loading="Loading" icon="el-icon-circle-check">创建账号</el-button>
						<el-button @click="goBack" icon="el-icon-circle-close">取消</el-button>
					</el-form-item>
				</el-form>
			</el-col>
			<el-col :span="12">
				<img :src="showImg" class="showImg" />
			</el-col>
		</el-row>

		<el-dialog title="店铺账号创建成功" :visible.sync="dialogShow" width="400px" center :before-close="hideDialog">
			<div id="copyBox">
				<p>用户名：{{userInfo.username}}</p>
				<p>密码：{{userInfo.password}}</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="copy('用户名：' + userInfo.username+',密码：' + userInfo.password)">一键复制</el-button>
				<el-button @click="goBack">关 闭</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import {
		addstore,
		showProvince,
		showCity,
		getUploadToken,
		uploadFiles
	} from '../../api/api';

	export default {
		data() {
			return {
				isEdit: false, //是否为编辑
				dialogShow: false,
				//表单验证规则
				formRules: {
					contact: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					identity_num: [{
						required: true,
						message: '请输入联系人身份号',
						trigger: 'blur'
					}]
				},
				//创建新增
				Loading: false,
				showImg: '',
				certificate_pic: [],
				//新增界面数据
				form: {
					agent_certificate_pic: [],
					identity_pic: {
						front: '',
						contrary: ''
					},
					contact: '',
					identity_num: '',
					sport_lottery: 0,
					sport_lottery_seq: '',
					welfare_lottery: 0,
					welfare_lottery_seq: '',
					province_id: '',
					city_id: ''
				},
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {}, //上传文件需要携带的参数
				imgUrlHead: '',
				provinceList: [],
				cityList: [],
				userInfo: {} //创建成功获取到的用户信息
			}
		},
		watch: {
			'form.province_id': function(newVal, oldVal) {
				if (newVal != oldVal) {
					this.getCityList()
				}
			}
		},
		methods: {
			copy(txt) {
				const input = document.createElement('input');
				document.body.appendChild(input);
				input.setAttribute('value', txt);
				input.select();
				if (document.execCommand('copy')) {
					document.execCommand('copy');
					this.$message({
						message: '复制成功！',
						type: 'success'
					});
					this.dialogShow = false;
					this.$router.push({
						name: "店铺账号管理"
					})
				}
				document.body.removeChild(input);
			},
			handleRemove(file) {
				var url = file.url;
				this.form.agent_certificate_pic.some((item, index) => {
					if (item.url == url) {
						this.form.agent_certificate_pic.splice(index, 1)
						return true
					}
				})
				console.log(this.form.agent_certificate_pic)
			},
			handlePictureCardPreview(file) {
				this.showImg = file.url;
			},
			//上传图片成功回调
			uploaderCertificateSuccess(response, file) {
				// this.form.agent_certificate_pic.push(file);
				this.form.agent_certificate_pic.push({
					// name: response.key,
					url: this.imgUrlHead + response.key
				});
			},
			exceed() {
				this.$message.error('代销证不能超过4张')
				// console.log(this.form.agent_certificate_pic)
			},
			uploaderIdentityFrontSuccess(response) {
				this.form.identity_pic.front = this.imgUrlHead + response.key;
			},
			uploaderIdentityContrarySuccess(response) {
				this.form.identity_pic.contrary = this.imgUrlHead + response.key;
			},
			//获取上传文件的token
			getUploadTokenM() {
				getUploadToken().then((res) => {
					this.dataToken = {
						token: res.data.uptoken
					}
					this.imgUrlHead = 'http://' + res.data.url + '/'
				});
			},
			//取消返回
			goBack() {
				this.$router.go(-1);
			},
			hideDialog(done) {
				done();
				this.goBack()
			},
			//获取省份
			getProvinceList() {
				showProvince().then((res) => {
					if (res.code == 1) {
						this.provinceList = res.data;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//获取县市
			getCityList() {
				let para = {
					pid: this.form.province_id
				};
				showCity(para).then((res) => {
					if (res.code == 1) {
						this.cityList = res.data;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//新增
			addSubmit: function() {
				if (this.form.agent_certificate_pic.length == 0) return this.$message.error('请上传代销证')
				if (!this.form.identity_pic.front) return this.$message.error('请上传身份证正面')
				if (!this.form.identity_pic.contrary) return this.$message.error('请上传身份证反面')
				this.$refs.form.validate((valid) => {
					if (valid) {
						if (!this.form.sport_lottery_seq && !this.form.welfare_lottery_seq) return this.$message.error(
							'体彩编号和福彩编号至少填写一个')
						if (!this.form.province_id) return this.$message.error('请选择省份')
						if (!this.form.city_id) return this.$message.error('请选择县市')
						if (this.form.sport_lottery_seq) this.form.sport_lottery = 1;
						if (this.form.welfare_lottery_seq) this.form.welfare_lottery = 1;
						// this.form.agent_certificate_pic = JSON.stringify(this.form.agent_certificate_pic);
						// this.form.identity_pic = JSON.stringify(this.form.identity_pic);
						this.$confirm('确认提交吗？', '提示', {}).then(() => {
							this.Loading = true;
							// let para = Object.assign({}, this.form);
							let para = this.form;
							addstore(para).then((res) => {
								this.Loading = false;
								if (res.code == 1) {
									this.userInfo = res.data;
									this.dialogShow = true;
								} else {
									this.$message.error(res.msg);
								}
							});
						}).catch((res) => {
							// this.form.identity_pic = JSON.parse(this.form.identity_pic);
						})
					}
				});
			},
		},
		mounted() {
			// 获取省份
			this.getProvinceList()
			//获取上传文件token
			this.getUploadTokenM();
		}
	}
</script>
<style lang="scss" scoped>
	.showImg {
		max-width: 100%;
	}

	.el-form-item__content {
		display: flex;
	}

	.avatar-uploader {
		width: 148px;
		height: 148px;
		display: flex;
		align-items: center;
		margin-right: 15px;
		float: left;
		border-radius: 6px;
		overflow: hidden;
		background-color: #cccccc;
		position: relative;
	}
	.seeBigImg{
		position: absolute;
		right: 0px;
		bottom: 0px;
		width: 44px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-top-left-radius: 8px;
		background-color: rgba(0,0,0,0.8);
		color: #ffffff;
	}
	.avatar-uploader .el-upload {
		display: block;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		border: none
	}

	.avatar-uploader .el-upload img {
		width: 100%
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
		border-radius: 6px;
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
	}

	.avatar {
		width: 100%;
		display: block;
	}

	p {
		padding: 8px 0
	}
</style>
